import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  buildMap,
  parseNoise,
  signalCompare,
} from '../utils/wirelessParserFunctions'

import {
  Typography,
  Grid2 as Grid,
  Paper,
  Box,
  LinearProgress,
  Stack,
  Tooltip,
  CircularProgress,
} from '@mui/material'
import { useTheme } from '@emotion/react'
import { useGetDevice, useJoinDeviceRoom, useStaTunnel } from '../hooks'
import WirelessDeviceCard from '../Components/Network/LTUDashboard/WirelessDeviceCard'
import WirelessDetailCard from '../Components/Network/LTUDashboard/WirelessDetailCard'
import NetworkMap from '../Components/Maps/Geolocation/NetworkMap'
import ChartTabs from '../Components/Network/LTUDashboard/ChartTabs'
import WirelessBar from '../Components/Network/LTUDashboard/WirelessBar'
import { useAppContext } from '../context/AppProvider'

const WirelessScreen = () => {
  const { ws } = useAppContext()
  const theme = useTheme()
  const { id } = useParams()
  const timer = useRef()

  const { ap, stas, site, loading } = useSelector((state) => state.wsDevice)
  const wsNoiseData = useSelector((state) => state.wsNoiseData)

  const [sta, setSta] = useState(null)
  const [live, setLive] = useState(false)

  let apNoise = ap?.wireless?.noise?.apNoise?.pwr_avgs
  let stasNoise = ap?.wireless?.noise?.staCombinedNoise?.pwr_avgs

  useEffect(() => {
    setLive(true)
    clearTimeout(timer.current)
    if (stas) {
      const station = stas.find((sta) => sta._id === id)
      setSta(station)
    }
    timer.current = setTimeout(() => {
      setLive(false)
    }, 10000)
  }, [ap, stas])

  const mapData = () => {
    let data
    if (sta) {
      data = { ...sta }
      data.site = site
    } else {
      data = { ...ap }
      data.site = site
    }
    data.stations = stas
    return data
  }

  useGetDevice(id)
  useJoinDeviceRoom(id)
  useStaTunnel(ap?._id, sta)

  // useEffect(() => {
  //   if (ap?._id) {
  //     ws.joinDeviceRoom(ap._id)
  //     return () => {
  //       ws.leaveDeviceRoom(ap._id)
  //     }
  //   }
  // }, [])

  console.log(theme.mixins.toolbar)

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Paper
          sx={{
            height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
          }}
        >
          <WirelessBar ap={ap} sta={sta} site={site} live={live} />
          <Stack
            direction="column"
            spacing={1}
            sx={{
              p: 1,
            }}
          >
            <Grid container spacing={1}>
              <Grid
                size={{ xs: 6, md: 3 }}
                order={{ xs: 2, md: 1 }}
                sx={{ bgcolor: ap?.connected ? null : 'darkred' }}
              >
                <WirelessDeviceCard data={ap} />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }} order={{ xs: 1, md: 2 }}>
                {site.length && <NetworkMap sites={buildMap(mapData())} />}
              </Grid>
              <Grid size={{ xs: 6, md: 3 }} order={{ xs: 2, md: 3 }}>
                <div>
                  {sta ? (
                    <WirelessDeviceCard data={sta} />
                  ) : (
                    <WirelessDetailCard data={ap} />
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid size={{ xs: 12, lg: 3 }} order={{ xs: 2, lg: 1 }}>
                {sta && <WirelessDetailCard data={ap} />}
              </Grid>
              <Grid size={{ xs: 12, lg: 6 }} order={{ xs: 1, lg: 2 }}>
                <Grid
                  container
                  direction="column"
                  spacing={1}
                  sx={{
                    justifyContent: 'space-evenly',
                  }}
                >
                  {apNoise && (
                    <Grid>
                      <Paper elevation={5} sx={{ p: 1 }}>
                        <Typography>AP Noise</Typography>
                        <Stack direction="row">
                          {parseNoise(ap, apNoise).map((el, i) => (
                            <Stack sx={{ flexGrow: 1 }} key={i}>
                              {el.utilized && (
                                <Box
                                  sx={{
                                    flexGrow: 1,
                                    backgroundColor: 'black',
                                    height: '3px',
                                  }}
                                />
                              )}
                              <Tooltip
                                title={
                                  <>
                                    <Typography>{el.channel}MHz</Typography>
                                    <Typography alignItems="center">
                                      {el.noise}dBm
                                    </Typography>
                                  </>
                                }
                              >
                                <Box
                                  sx={{
                                    flexGrow: 1,
                                    backgroundColor: el.color,
                                    height: '30px',
                                  }}
                                />
                              </Tooltip>
                              {el.utilized && (
                                <Box
                                  sx={{
                                    flexGrow: 1,
                                    backgroundColor: 'black',
                                    height: '3px',
                                  }}
                                />
                              )}
                            </Stack>
                          ))}
                        </Stack>
                      </Paper>
                    </Grid>
                  )}
                  {stasNoise && (
                    <Grid>
                      <Paper elevation={5} sx={{ p: 1 }}>
                        <Typography>Station Noise</Typography>
                        <Stack direction="row">
                          {parseNoise(ap, stasNoise)?.map((el, i) => (
                            <Stack sx={{ flexGrow: 1 }} key={i}>
                              {el.utilized && (
                                <Box
                                  sx={{
                                    flexGrow: 1,
                                    backgroundColor: 'black',
                                    height: '3px',
                                  }}
                                />
                              )}
                              <Tooltip
                                title={
                                  <>
                                    <Typography>{el.channel}MHz</Typography>
                                    <Typography alignItems="center">
                                      {el.noise}dBm
                                    </Typography>
                                  </>
                                }
                              >
                                <Box
                                  sx={{
                                    flexGrow: 1,
                                    backgroundColor: el.color,
                                    height: '30px',
                                  }}
                                />
                              </Tooltip>
                              {el.utilized && (
                                <Box
                                  sx={{
                                    flexGrow: 1,
                                    backgroundColor: 'black',
                                    height: '3px',
                                  }}
                                />
                              )}
                            </Stack>
                          ))}
                        </Stack>
                      </Paper>
                    </Grid>
                  )}
                  {ap?.wireless?.dlUtilization !== undefined && (
                    <Grid>
                      <Paper elevation={5} sx={{ p: 1 }}>
                        <Typography>AP Utilization</Typography>
                        <LinearProgress
                          color="success"
                          sx={{ height: '5px' }}
                          variant="buffer"
                          valueBuffer={ap?.wireless?.dlUtilization}
                          value={sta ? sta?.wireless?.dlUtilization : 0}
                        />
                        <LinearProgress
                          color="secondary"
                          sx={{
                            height: '5px',
                            transform: 'rotate(180deg)',
                          }}
                          variant="buffer"
                          valueBuffer={ap?.wireless?.ulUtilization}
                          value={sta ? sta?.wireless?.ulUtilization : 0}
                        />
                      </Paper>
                    </Grid>
                  )}
                  {sta && (
                    <Grid container>
                      <Grid size={12} container direction="column">
                        {sta?.family === 'wave' ? (
                          <>
                            {sta.wireless?.radios?.map((radio, i) => (
                              <Paper elevation={5} sx={{ p: 1 }}>
                                <Grid
                                  xs
                                  container
                                  justifyContent="space-between"
                                >
                                  <Grid xs>
                                    <Grid xs>
                                      <Stack>
                                        <Typography>{radio.name}</Typography>
                                        <Typography
                                          color={
                                            radio.linkState === 'active' ||
                                            radio.linkState === 'connected'
                                              ? 'green'
                                              : 'red'
                                          }
                                          variant="body1"
                                        >
                                          {radio.linkState}
                                        </Typography>
                                      </Stack>
                                    </Grid>
                                  </Grid>
                                  <Grid xs>
                                    <Box>
                                      <Typography
                                        variant="h3"
                                        color={signalCompare(
                                          radio.linkQuality?.idealSignal,
                                          radio.linkQuality?.signal
                                        )}
                                        alignItems="center"
                                      >
                                        {radio.linkQuality?.signal}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid xs>
                                    {radio.linkQuality?.idealSignal && (
                                      <Typography alignItems="right">
                                        Expected{' '}
                                        {radio.linkQuality?.idealSignal}
                                        dBm
                                      </Typography>
                                    )}
                                  </Grid>
                                </Grid>
                              </Paper>
                            ))}
                          </>
                        ) : (
                          <Paper elevation={5} sx={{ p: 1 }}>
                            <Grid xs container justifyContent="space-between">
                              <Grid xs>
                                <Typography>CPE Signal</Typography>
                              </Grid>
                              <Grid xs>
                                <Box>
                                  <Typography
                                    variant="h3"
                                    color={signalCompare(
                                      sta?.wireless?.expectedSignal,
                                      sta?.wireless?.signal
                                    )}
                                    alignItems="center"
                                  >
                                    {sta?.wireless?.signal}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid xs>
                                {sta?.wireless?.expectedSignal && (
                                  <Typography alignItems="right">
                                    Expected {sta?.wireless?.expectedSignal}
                                    dBm
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                            {sta?.wireless?.signalScore && (
                              <Grid>
                                <LinearProgress
                                  color="success"
                                  sx={{ height: '5px' }}
                                  variant="determinate"
                                  value={sta?.wireless?.signalScore}
                                />
                              </Grid>
                            )}
                          </Paper>
                        )}
                      </Grid>
                    </Grid>
                  )}
                  {ap?.family === 'aviat' && (
                    <Grid container>
                      <Grid xs={12} container direction="column">
                        <Paper elevation={5} sx={{ p: 1, flexGrow: 1 }}>
                          <Grid xs container justifyContent="space-between">
                            <Grid xs>
                              <Typography>Signal</Typography>
                            </Grid>
                            <Grid xs>
                              <Box>
                                <Typography
                                  variant="h3"
                                  color={signalCompare(
                                    ap?.wireless?.expectedSignal,
                                    ap?.wireless?.signal
                                  )}
                                  alignItems="center"
                                >
                                  {ap?.wireless?.signal}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid xs>
                              {ap?.wireless?.expectedSignal && (
                                <Typography alignItems="right">
                                  Expected {ap?.wireless?.expectedSignal}dBm
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                          {ap?.wireless?.expectedSignal && (
                            <Grid>
                              <LinearProgress
                                color="success"
                                sx={{ height: '5px' }}
                                variant="determinate"
                                value={Math.round(
                                  (ap?.wireless?.expectedSignal /
                                    ap?.wireless?.signal) *
                                    100
                                )}
                              />
                            </Grid>
                          )}
                        </Paper>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {sta && (
                <Grid size={{ xs: 12, sm: 12, lg: 3 }} order={{ xs: 3, lg: 3 }}>
                  <WirelessDetailCard data={sta} />
                </Grid>
              )}
            </Grid>
            <ChartTabs device={sta ? sta : ap} ap={ap} sta={sta} stas={stas} />
          </Stack>
        </Paper>
      )}
    </>
  )
}

export default WirelessScreen
