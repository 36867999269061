import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Box, Paper, TextField, InputAdornment, IconButton, CircularProgress } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useAppContext } from '../../../context/AppProvider'
import SearchTabs from './Tabs'
import Results from './Results'

const SearchDialog = () => {
  const { ws } = useAppContext()
  const { results: { sites, subscribers, devices }, loading } = useSelector(state => state.wsSearch)
  const [searchText, setSearchText] = useState('')
  const [showResults, setShowResults] = useState(false)
  const [tab, setTab] = useState('1')
  const dialogRef = useRef(null)

  const handleClose = () => {
    setShowResults(false)
  }

  const handleSearchInput = (e) => {
    setSearchText(e.target.value)
    if (e.target.value.length >= 3) {
      setShowResults(true)
    } else {
      setShowResults(false)
    }
  }

  const clearSearch = () => {
    setSearchText('')
    setShowResults(false)
  }

  useEffect(() => {
    if (searchText.length >= 3) {
      const searchTimeout = setTimeout(() => {
        ws.searchDevices(searchText)
      }, 500)
      return () => clearTimeout(searchTimeout)
    }
  }, [searchText, ws])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        handleClose()
      }
    }

    // Add click event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Clean up the event listener
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [dialogRef])

  return (
    <Box ref={dialogRef} sx={{ display: 'flex', justifyContent: 'center', position: 'relative', width: '100%' }}>
      <TextField
        autoComplete="off"
        id="search-text-field"
        label="Search"
        value={searchText}
        onChange={handleSearchInput}
        onFocus={() => searchText.length >= 3 && setShowResults(true)}
        InputProps={{
          endAdornment: loading ? (
            <CircularProgress color="inherit" size={20} />
          ) : searchText ? (
            <InputAdornment position="end">
              <IconButton onClick={clearSearch}>
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ) : null,
        }}
      />
      {showResults && (
        <Paper sx={{
          position: 'absolute',
          zIndex: 2,
          top: '100%', // Position just below the TextField
          left: '50%', // Center relative to the parent
          transform: 'translateX(-50%)', // Correctly center the Paper
          width: 'auto', // Adjust width as needed
          minWidth: '100%', // At least as wide as the parent
          maxWidth: 'calc(100vw - 40px)', // Avoid viewport overflow
          maxHeight: '60vh', // Set a maximum height for the Paper component
          overflowY: 'auto', // Enable vertical scrolling
          boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
          marginTop: '8px',
          borderRadius: '4px',
        }}>
          <Results sites={sites} devices={devices} subscribers={subscribers} />
          
        </Paper>
      )}
    </Box>
  )
}

export default SearchDialog