import { Typography, Grid2 as Grid, Paper, Divider, Stack } from '@mui/material'

const WirelessDetailCard = ({ data }) => {
  const formatThroughput = (bitData) => {
    switch (data?.family) {
      case 'ltu':
        return parseFloat(bitData / 1000000).toFixed(2)
      case 'af5xhd':
        return parseFloat(bitData / 1000000).toFixed(2)
      case 'wave':
        return parseFloat(bitData / 1000000).toFixed(2)
      case 'af60':
        return parseFloat(bitData / 1000000).toFixed(2)
      case 'airmax':
        return parseFloat(bitData / 1000000).toFixed(2)
      case 'epmp':
        return parseFloat(bitData / 1000000).toFixed(2)
      case 'aviat':
        return parseFloat(bitData / 1000000).toFixed(2)
      default:
        return parseFloat(bitData / 1000000).toFixed(2)
    }
  }

  return (
    <Paper
      elevation={5}
      sx={{
        p: 1,
      }}
    >
      <Stack direction="column">
        <Divider style={{ width: '100%' }}>Wireless</Divider>
        {data?.wireless?.ssid != null && (
          <Grid container justifyContent="space-between">
            <Grid>
              <Typography variant="body2" color="text.secondary">
                Network Name:
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="body2" color="text.secondary">
                {data?.wireless.ssid}
              </Typography>
            </Grid>
            <Divider style={{ width: '100%' }} />
          </Grid>
        )}
        {data?.wireless?.passphrase != null && (
          <Grid container justifyContent="space-between">
            <Grid>
              <Typography variant="body2" color="text.secondary">
                Passphrase:
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="body2" color="text.secondary">
                {data?.wireless?.passphrase}
              </Typography>
            </Grid>
            <Divider style={{ width: '100%' }} />
          </Grid>
        )}
        {data?.wireless?.activeFrequency != null && (
          <Grid container justifyContent="space-between">
            <Grid>
              <Typography variant="body2" color="text.secondary">
                Frequency:
              </Typography>
            </Grid>
            <Grid>
              {data?.wireless?.activeFrequency === data?.wireless?.frequency ? (
                <Typography variant="body2" color="text.secondary">
                  {data?.wireless?.activeFrequency}
                </Typography>
              ) : (
                <Typography
                  variant="body2"
                  color="error"
                >{`${data?.wireless?.activeFrequency} (Alternate)`}</Typography>
              )}
            </Grid>
            <Divider style={{ width: '100%' }} />
          </Grid>
        )}
        {data?.wireless?.dlThroughput != null && (
          <Grid container justifyContent="space-between">
            <Grid>
              <Typography variant="body2" color="text.secondary">
                {data?.function.includes('sta') ? 'RX' : 'TX'} Throughput:
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="body2" color="text.secondary">
                {formatThroughput(data?.wireless.dlThroughput)} Mbps
              </Typography>
            </Grid>
            <Divider style={{ width: '100%' }} />
          </Grid>
        )}
        {data?.wireless?.ulThroughput != null && (
          <Grid container justifyContent="space-between">
            <Grid>
              <Typography variant="body2" color="text.secondary">
                {data?.function.includes('sta') ? 'TX' : 'RX'} Throughput:
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="body2" color="text.secondary">
                {formatThroughput(data?.wireless.ulThroughput)} Mbps
              </Typography>
            </Grid>
            <Divider style={{ width: '100%' }} />
          </Grid>
        )}
        {data?.wireless?.dlUtilization != null && (
          <Grid container justifyContent="space-between">
            <Grid>
              <Typography variant="body2" color="text.secondary">
                {data?.function.includes('sta') ? 'RX' : 'TX'} Utilization:
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="body2" color="text.secondary">
                {data?.wireless.dlUtilization}%
              </Typography>
            </Grid>
            <Divider style={{ width: '100%' }} />
          </Grid>
        )}
        {data?.wireless?.ulUtilization != null && (
          <Grid container justifyContent="space-between">
            <Grid>
              <Typography variant="body2" color="text.secondary">
                {data?.function.includes('sta') ? 'TX' : 'RX'} Utilization:
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="body2" color="text.secondary">
                {data?.wireless.ulUtilization}%
              </Typography>
            </Grid>
            <Divider style={{ width: '100%' }} />
          </Grid>
        )}
        {data?.wireless?.dlCapacity != null && (
          <Grid container justifyContent="space-between">
            <Grid>
              <Typography variant="body2" color="text.secondary">
                {data?.function.includes('sta') ? 'RX/TX' : 'TX/RX'} Capacity:
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="body2" color="text.secondary">
                {formatThroughput(data?.wireless.dlCapacity)}/
                {formatThroughput(data?.wireless.ulCapacity)} Mbps
              </Typography>
            </Grid>
            <Divider style={{ width: '100%' }} />
          </Grid>
        )}
        {data?.wireless?.dlScore != null && (
          <Grid container justifyContent="space-between">
            <Grid>
              <Typography variant="body2" color="text.secondary">
                Download Score:
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="body2" color="text.secondary">
                {data?.wireless.dlScore}%
              </Typography>
            </Grid>
            <Divider style={{ width: '100%' }} />
          </Grid>
        )}
        {data?.wireless?.dlScore != null && (
          <Grid container justifyContent="space-between">
            <Grid>
              <Typography variant="body2" color="text.secondary">
                Upload Score:
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="body2" color="text.secondary">
                {data?.wireless.ulScore}%
              </Typography>
            </Grid>
            <Divider style={{ width: '100%' }} />
          </Grid>
        )}
        {data?.accelerometer?.xydegreescurr != null && (
          <Grid container justifyContent="space-between">
            <Grid>
              <Typography variant="body2" color="text.secondary">
                Rotation:
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="body2" color="text.secondary">
                {(data?.accelerometer.xydegreescurr - 90).toFixed(2)}°
              </Typography>
            </Grid>
            <Divider style={{ width: '100%' }} />
          </Grid>
        )}
        {data?.accelerometer?.zdegreescurr != null && (
          <Grid container justifyContent="space-between">
            <Grid>
              <Typography variant="body2" color="text.secondary">
                Tilt:
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="body2" color="text.secondary">
                {(data?.accelerometer.zdegreescurr - 90).toFixed(2)}°
              </Typography>
            </Grid>
            <Divider style={{ width: '100%' }}>Host</Divider>
          </Grid>
        )}
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid>
            <Typography variant="body2" color="text.secondary">
              Firmware:
            </Typography>
          </Grid>
          <Grid>
            <Typography variant="body2" color="text.secondary">
              {data?.firmware}
            </Typography>
          </Grid>
          <Divider style={{ width: '100%' }} />
        </Grid>
        {data?.shaping != null && (
          <>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid>
                <Typography variant="body2" color="text.secondary">
                  DL Limit:
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="body2" color="text.secondary">
                  {data?.shaping?.downloadRate}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ width: '100%' }} />
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid>
                <Typography variant="body2" color="text.secondary">
                  UL Limit:
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="body2" color="text.secondary">
                  {data?.shaping?.uploadRate}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ width: '100%' }} />
          </>
        )}
        {data?.lan?.lanSpeed != null && (
          <Grid container justifyContent="space-between">
            <Grid>
              <Typography variant="body2" color="text.secondary">
                LAN Status:
              </Typography>
            </Grid>

            {data?.lan.lanSpeed ? (
              <Grid>
                <Typography variant="body2" color="text.secondary">{`${
                  data?.lan.lanSpeed
                }Mbps${
                  data?.lan.lanDuplex === undefined
                    ? '/Full'
                    : data?.lan.lanDuplex
                      ? '/Full'
                      : '/Half'
                }`}</Typography>
              </Grid>
            ) : (
              <Grid>
                <Typography variant="body2" color="error">
                  NO LAN
                </Typography>
              </Grid>
            )}
            <Divider style={{ width: '100%' }} />
          </Grid>
        )}
        {data?.lan?.lanTxErrors != null && (
          <Grid container justifyContent="space-between">
            <Grid>
              <Typography variant="body2" color="text.secondary">
                TX Errors:
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="body2" color="text.secondary">
                {data?.lan.lanTxErrors}
              </Typography>
            </Grid>
            <Divider style={{ width: '100%' }} />
          </Grid>
        )}
        {data?.lan?.lanRxErrors != null && (
          <Grid container justifyContent="space-between">
            <Grid>
              <Typography variant="body2" color="text.secondary">
                RX Errors:
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="body2" color="text.secondary">
                {data?.lan.lanRxErrors}
              </Typography>
            </Grid>

            <Divider style={{ width: '100%' }} />
          </Grid>
        )}
        {data?.mac != null && (
          <Grid container justifyContent="space-between">
            <Grid>
              <Typography variant="body2" color="text.secondary">
                MAC:
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="body2" color="text.secondary">
                {data?.mac}
              </Typography>
            </Grid>
            <Divider style={{ width: '100%' }} />
          </Grid>
        )}
      </Stack>
    </Paper>
  )
}

export default WirelessDetailCard
