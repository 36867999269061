import { Link } from 'react-router-dom'
import Moment from 'react-moment'
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Grid,
  Divider,
  ListSubheader
} from '@mui/material'
import ltuRocketImg from '../../../images/ltuRocket.png'
import ltuLrImg from '../../../images/ltulr.png'
import ltuProImg from '../../../images/ltupro.png'
import ltuLiteImg from '../../../images/ltulite.png'
import { generateUrl } from '../../../utils/generateUrl'




const DevicesResults = ({ handleClose, devices }) => {

  const imgSel = (type) => {
    let img
    switch (type) {
      case 'LTU-LR':
        img = ltuLrImg
        break
      case 'LTU-Pro':
        img = ltuProImg
        break
      case 'LTU-Lite':
        img = ltuLiteImg
        break
      case 'LTU-Rocket':
        img = ltuRocketImg
        break
    }
    return img
  }

  return (
    <List subheader={
      <ListSubheader component="div" id="nested-list-subheader">
        <Typography variant='h6'>Devices</Typography>
      </ListSubheader>
    }>
      {devices?.map((el) => {
        return (
          <Link
            key={el._id}
            style={{ textDecoration: 'inherit', color: 'inherit' }}
            to={generateUrl(el)}
            onClick={handleClose}>
            <ListItem alignItems='flex-start'>
              <ListItemAvatar>
                <Avatar
                  src={imgSel(el.model)}
                  sx={{ bgcolor: 'inherit' }}
                  aria-label='recipe'
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Grid
                    container
                    direction='row'
                    justifyContent='space-between'>
                    <Grid item>
                      <Typography
                        sx={{ display: 'inline' }}
                        component='span'
                        variant='body2'
                        color='text.primary'>
                        {el.name}
                      </Typography>
                    </Grid>
                    <Grid item>
                      {!el.connected && (
                        <Typography
                          align='center'
                          variant='subtitle2'
                          component='div'>
                          Last seen{' '}
                          <Moment fromNow>{el.lastContact}</Moment>
                        </Typography>
                      )}
                    </Grid>
                    <Grid item>
                      <Typography
                        sx={{ display: 'inline' }}
                        component='span'
                        variant='body2'
                        color='text.primary'>
                        {el?.subscriber?.name}
                      </Typography>
                    </Grid>
                  </Grid>
                }
                secondary={
                  <Grid
                    container
                    direction='row'
                    justifyContent='space-between'>
                    <Grid item>
                      <Typography
                        sx={{ display: 'inline' }}
                        component='span'
                        variant='body2'
                        color='text.primary'>
                        {el.model}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography align='right'>
                        <a
                          style={{
                            textDecoration: 'inherit',
                            color: 'inherit',
                          }}
                          target='_blank'
                          href={`http://${el.ip}`}>
                          {el.ip}
                        </a>
                      </Typography>
                    </Grid>
                  </Grid>
                }
              />
            </ListItem>
            <Divider variant='inset' component='li' />
          </Link>
        )
      })}
    </List>
  )
}

export default DevicesResults