import React, { useState } from 'react'
import {
  Typography,
  Grid,
  Paper,
  Box,
  Tooltip,
  Collapse,
  Divider,
  Stack,
  List,
  ListItemText,
} from '@mui/material'

import { Bolt, FlashAuto, OpenInNew } from '@mui/icons-material'
import { DataGrid } from '@mui/x-data-grid'
import { useUptime } from '../../../hooks'
import DeviceMenu from '../../Core/Device/DeviceMenu'
import { Link } from 'react-router-dom'
import { useTheme } from '@emotion/react'

const Mikrotik = ({ device, devices, edges }) => {
    const theme = useTheme()
  const uptime = useUptime(device.uptime)
  const [collapsed, setCollapsed] = useState(false)
  const [editRowsModel, setEditRowsModel] = useState({})

  const getReadableFileSizeString = (fileSizeInBytes) => {
    if (fileSizeInBytes < 100) return null
    let i = -1
    const byteUnits = [
      "kbps",
      "Mbps",
      "Gbps",
      "Tbps",
      "Pbps",
      "Ebps",
      "Zbps",
      "Ybps"
    ]
    do {
      fileSizeInBytes = fileSizeInBytes / 1000
      i++
    } while (fileSizeInBytes > 1000)

    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i]
  }

  const handleEditRowsModelChange = React.useCallback((model) => {
    setEditRowsModel(model)
  }, [])

  const InterfaceBox = ({ iface }) => {
    const boxColor = () => {
      if (iface.running === 'false') {
        return 'gray'
      }
      switch (iface.connectionData?.rate) {
        case '10Gbps':
          return 'white'
        case '1Gbps':
          return 'green'
        case '100Mbps':
          return 'orange'
        case '10Mbps':
          return 'red'
        default:
          return 'purple'
      }
    }

    return (
      <Tooltip
        arrow
        title={
          <Stack>
            <Typography>{iface.name}</Typography>
            <Typography>{iface?.connectionData?.rate ? iface.connectionData.rate : 'No Link'}</Typography>
          </Stack>
        }>
        <Box
          sx={{
            backgroundColor: boxColor(),
            height: '20px',
            width: '20px',
          }}
        />
      </Tooltip>
    )
  }

  const columns = [
    {
      field: 'host',
      headerName: 'Host',
      flex: 0.5,
      renderCell: (params) => {
        const connectedEdges = edges
          .filter((edge) => edge?.from === device._id.toString())
        const connectedEdge = connectedEdges.find(el => el.port === params?.row?.name?.toString())
        const connectedDevice = devices.find(device => device._id.toString() === connectedEdge?.to)
        return (
          connectedDevice && (
            <Link
            style={{ textDecoration: 'inherit', color: connectedDevice?.connected ? theme.palette.primary.main : theme.palette.secondary.main }}
            to={`/network/device/${connectedDevice?._id}`}
          >
            <Stack direction='row' spacing={2} sx={{ justifyContent: "space-between", alignItems: "center" }}>
              <Typography>{`${connectedDevice?.name}  `}</Typography>
              <OpenInNew fontSize='small' />
            </Stack>
            
          </Link>
          )
        )
      }
    },
    {
      field: 'name',
      headerName: 'Name',
      editable: true,
      flex: 0.5,
      valueGetter: (params) => params.row.name,
    },
    {
      field: 'addresses',
      headerName: 'Address',
      editable: true,
      flex: 1,
      renderCell: (params) => (
        <List>
          {
            params.row.addresses.map((addr) => (
              <ListItemText key={addr['.id']} primary={<Typography variant='caption'>{addr.address}</Typography>} />
            ))
          }
        </List>

      )
    },
    {
      field: 'rxbps',
      headerName: 'RX',
      flex: 0.5,
      valueGetter: (params) =>
        getReadableFileSizeString(params?.row?.throughputData['rx-bits-per-second'])
    },
    {
      field: 'txbps',
      headerName: 'TX',
      flex: 0.5,
      valueGetter: (params) =>
        getReadableFileSizeString(params?.row?.throughputData['tx-bits-per-second'])
    },
    {
      field: 'rate',
      headerName: 'Speed',
      flex: 0.5,
      valueGetter: (params) =>
        params?.row?.connectionData?.rate
    }
  ]

  return (
    <Paper elevation={5} sx={{ p: 1, flexGrow: 1 }}>
      <Grid
        backgroundColor={!device.connected && 'red'}
        container
        direction='column'
        sx={{ p: 2 }}
        justifyContent='space-evenly'>
        <Grid item>
          <Grid item container direction='row' justifyContent='space-between'>
            <Grid item>
              <Typography variant='h6'>{device.name}</Typography>
            </Grid>
            <Grid item>
              <DeviceMenu device={device} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction='row' justifyContent='space-between'>
          <Grid item>
            <Typography variant='caption' component='div'>
              {uptime}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid
          item
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          onClick={() => setCollapsed(!collapsed)}>
          <Grid item>
            <Grid
              item
              container
              direction='column'
              justifyContent='space-between'>
              <Grid item>
                <Typography>{device?.model}</Typography>
              </Grid>
              <Grid item>
                <Typography>
                  <a
                    onClick={(e) => e.stopPropagation()}
                    style={{
                      textDecoration: 'inherit',
                      color: 'inherit',
                    }}
                    target='_blank'
                    href={`http://${device.ip}`}>
                    {device.ip}
                  </a>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='caption' component='div'>
                  {device?.mac?.toUpperCase()}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {device.routing.interfaces.length > 0 && (
              <Grid item>
                <Paper elevation={0} sx={{ p: 1, flexGrow: 1 }}>
                  <Grid item container direction='row'>
                    {device.routing.interfaces
                      .filter((iface) => iface.interfaceType === 'physical')
                      .map((iface) => (
                        <Grid item xs key={iface['.id']}>
                          <Box sx={{ pr: 0.5, pl: 0.5 }}>
                            <InterfaceBox iface={iface} />
                          </Box>
                        </Grid>
                      ))}
                  </Grid>
                </Paper>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Divider />
        <Grid item>
          <Collapse in={collapsed}>
            <div style={{ display: 'flex' }}>
              <div style={{ flexGrow: 1 }}>
                <DataGrid
                  autoHeight
                  disableSelectionOnClick
                  editRowsModel={editRowsModel}
                  onEditRowsModelChange={handleEditRowsModelChange}
                  getRowId={(row) => row['.id']}
                  getRowHeight={() => 'auto'}
                  rows={device.routing.interfaces}
                  columns={columns}
                  sx={{
                    '& .MuiDataGrid-columnHeaderTitle': {
                      textOverflow: 'clip',
                      whiteSpace: 'break-spaces',
                      lineHeight: 1,
                    },
                    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                      outline: 'none',
                    },
                  }}
                />
              </div>
            </div>
          </Collapse>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default Mikrotik
