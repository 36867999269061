import React, { useState } from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Navigate,
  Outlet,
  useLocation,
} from 'react-router-dom'

import { useGeolocation, useJoinSocketRoom } from '../hooks'

import {
  CssBaseline,
  Divider,
  Box,
  AppBar,
  Drawer,
  Toolbar,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from '@mui/material'

import {
  Home,
  Groups,
  Hub,
  Settings,
  Menu,
  HomeRepairService,
  SettingsInputAntenna,
} from '@mui/icons-material'

import SitesListScreen from './SitesListScreen'
import DevicesListScreen from './DevicesListScreen'
import SiteScreen from './SiteScreen'
import WirelessScreen from './WirelessScreen'
// import BetaWirelessScreen from './BetaWirelessScreen'
import ToolsScreen from './ToolsScreen'
import EventLogScreen from './EventLogScreen'
import ApFinderScreen from './ApFinderScreen'
import TopologyScreen from './TopologyScreen'
import SubscriberScreen from './SubscriberScreen'
import DispatchablesScreen from './DispatchablesScreen'
import Login from '../Components/User/Login'
import AccountInfo from '../Components/User/AccountInfo'
import SearchDialog from '../Components/Core/Search/SearchDialog'
import ZoomIn from '../Components/Charts/TestZoom'
import Notification from '../Components/Core/NotificationSnackBar'
import { useAppContext } from '../context/AppProvider'
import PtpScreen from './PtpScreen'
import CreateUser from '../Components/Settings/CreateUser'
import UserList from '../Components/Settings/UserList'
import UserAccountScreen from './UserAccountScreen'
import FresnelZoneScreen from './FresnelZoneScreen'
import CreateSite from '../Components/Core/SiteCreation/CreateSite'

const drawerWidth = 240

const PrivateRoute = (props) => {
  const { user, isLoading, authFailure } = props
  const path = useLocation()
  console.log(path)
  if (isLoading) {
    return null
  }

  if (user) {
    return <Outlet />
  }

  if (authFailure) {
    return (
      <Navigate to="/login" replace state={{ destination: path.pathname }} />
    )
  }

  return null
}

const MainScreen = (props) => {
  const { user, isLoading, authFailure } = useAppContext()
  const { window } = props
  const [mobileOpen, setMobileOpen] = useState(false)
  const [open, setOpen] = useState(false)

  useJoinSocketRoom()

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <div sx={{ display: 'flex' }}>
      <Toolbar />
      <Divider />
      <List>
        <Link style={{ color: 'inherit', textDecoration: 'inherit' }} to="/">
          <ListItem button onClick={handleDrawerToggle}>
            <ListItemIcon>
              <Home />
            </ListItemIcon>
            <ListItemText primary={'Home'} />
          </ListItem>
        </Link>
        <Link
          style={{ color: 'inherit', textDecoration: 'inherit' }}
          to="/subscribers"
        >
          <ListItem button onClick={handleDrawerToggle}>
            <ListItemIcon>
              <Groups />
            </ListItemIcon>
            <ListItemText primary={'Subscribers'} />
          </ListItem>
        </Link>
        <Link
          style={{ color: 'inherit', textDecoration: 'inherit' }}
          to="/network/sites"
        >
          <ListItem button onClick={handleDrawerToggle}>
            <ListItemIcon>
              <Hub />
            </ListItemIcon>
            <ListItemText primary={'Sites'} />
          </ListItem>
        </Link>
        <Link
          style={{ color: 'inherit', textDecoration: 'inherit' }}
          to="/network/devices"
        >
          <ListItem button onClick={handleDrawerToggle}>
            <ListItemIcon>
              <SettingsInputAntenna />
            </ListItemIcon>
            <ListItemText primary={'Devices'} />
          </ListItem>
        </Link>
      </List>
    </div>
  )

  const bottomDrawer = (
    <div sx={{ display: 'flex' }}>
      <Divider />
      <List>
        <Link
          style={{ color: 'inherit', textDecoration: 'inherit' }}
          to="/events"
        >
          <ListItem button onClick={handleDrawerToggle}>
            <ListItemIcon>
              <HomeRepairService />
            </ListItemIcon>
            <ListItemText primary={'Events'} />
          </ListItem>
        </Link>
        <Link
          style={{ color: 'inherit', textDecoration: 'inherit' }}
          to="/tools"
        >
          <ListItem button onClick={handleDrawerToggle}>
            <ListItemIcon>
              <HomeRepairService />
            </ListItemIcon>
            <ListItemText primary={'Tools'} />
          </ListItem>
        </Link>
        <Link
          style={{ color: 'inherit', textDecoration: 'inherit' }}
          to="/settings"
        >
          <ListItem button onClick={handleDrawerToggle}>
            <ListItemIcon>
              <Settings />
            </ListItemIcon>
            <ListItemText primary={'Settings'} />
          </ListItem>
        </Link>
      </List>
    </div>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined
  return (
    <BrowserRouter>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          enableColorOnDark
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar
            sx={{
              alignItems: 'flex-center',
              justifyContent: 'space-between',
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <Menu />
            </IconButton>
            <Typography variant="h7" noWrap component="div">
              SugarGlider
            </Typography>
            {user && <SearchDialog setOpen={setOpen} open={open} />}
            {user && <AccountInfo />}
          </Toolbar>
        </AppBar>
        {user && (
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: drawerWidth,
                },
              }}
            >
              {drawer}
              <Box sx={{ flexGrow: 1 }} />
              {bottomDrawer}
            </Drawer>
            <Drawer
              variant="permanent"
              sx={{
                display: { xs: 'none', sm: 'block' },
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: drawerWidth,
                },
              }}
              open
            >
              {drawer}
              <Box sx={{ flexGrow: 1 }} />
              {bottomDrawer}
            </Drawer>
          </Box>
        )}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Notification />
          <Toolbar />
          <Routes>
            <Route
              path="/"
              element={
                <PrivateRoute
                  user={user}
                  isLoading={isLoading}
                  authFailure={authFailure}
                />
              }
            >
              <Route path="/" element={<SitesListScreen />} />
              <Route path="/events" element={<EventLogScreen />} />
              <Route path="/tools" element={<ToolsScreen />} />
              <Route path="/network/sites" element={<SitesListScreen />} />
              <Route path="/network/site/:siteId" element={<SiteScreen />} />
              {/* <Route
                path="/network/device/:apId"
                element={<WirelessScreen />}
              /> */}
              <Route path="/network/ptp/:id" element={<PtpScreen />} />
              <Route path="/network/device/:id" element={<WirelessScreen />} />
              <Route
                path="/network/device/:apId/:id"
                element={<WirelessScreen />}
              />
              <Route path="/network/devices" element={<DevicesListScreen />} />
              <Route path="/createuser" element={<CreateUser />} />
              <Route path="/zoom" element={<ZoomIn />} />
              <Route
                path="/subscriber/:subscriberId"
                element={<SubscriberScreen />}
              />
              <Route
                path="/subscribers/dispatchables"
                element={<DispatchablesScreen />}
              />
              <Route path="/tools/createsite" element={<CreateSite />} />
              <Route path="/tools/topology" element={<TopologyScreen />} />
              <Route path="/tools/apfinder" element={<ApFinderScreen />} />
              <Route path="/tools/fresnel" element={<FresnelZoneScreen />} />
              <Route
                path="/tools/apfinder/:lat/:lng"
                element={<ApFinderScreen />}
              />
              <Route path="/users" element={<UserList />} />
              <Route path="/users/:id" element={<UserAccountScreen />} />
            </Route>
            <Route path="/login" element={<Login />} />
          </Routes>
        </Box>
      </Box>
    </BrowserRouter>
  )
}

export default MainScreen
