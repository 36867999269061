import * as React from 'react'
import { useAppContext } from '../../../context/AppProvider'

import { AutoMode } from '@mui/icons-material'
import { Tooltip } from '@mui/material'

const CycleIface = ({ device, iface }) => {
  const { ws } = useAppContext()

  const [cycling, setCycling] = React.useState(false)

  const handleCycle = () => {
    if (!cycling) {
      ws.cycleIface({ device, iface })
      setCycling(true)
      setTimeout(() => {
        setCycling(false)
      }, 5000)
    }
  }

  return (
    <Tooltip title="Reset POE and Link" arrow>
      <AutoMode
        disabled={!cycling}
        sx={{
          '@keyframes spin': {
            '0%': { transform: 'rotate(0deg)' },
            '100%': { transform: 'rotate(360deg)' },
          },
          animation: cycling ? 'spin 2s linear infinite' : 'none',
        }}
        onClick={handleCycle}
      />
    </Tooltip>
  )
}

export default CycleIface
