import React from 'react'
import { Grid2, Box, Paper } from '@mui/material'
import SitesResults from './Sites'
import DevicesResults from './Devices'
import SubscriberResults from './Subscribers'


const Results = ({ sites, devices, subscribers }) => {
  return (
    <Grid2 container
      direction='row'
      spacing={4}
      justifyContent="space-evenly"
    >
      <Grid2 size={6} item>
        <SitesResults sites={sites} /> 
      </Grid2>
      <Grid2 size={6} item>
        <DevicesResults devices={devices} />
      </Grid2>
      {/* <Grid2 size={4} item>
        <SubscriberResults subscribers={subscribers} />
      </Grid2> */}
    </Grid2>
  )
}

export default Results