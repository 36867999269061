import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from 'react'
import axios from 'axios'
import io from 'socket.io-client'
import { useDispatch } from 'react-redux'
import { requestEvents } from '../reduxSlices/wsEventsSlice'
import { resetSonar, requestSonar } from '../reduxSlices/wsSonarSlice'
import { requestSites, resetSites } from '../reduxSlices/wsSitesSlice'
import { updateDevices } from '../reduxSlices/wsDevicesSlice'
import { requestDevices } from '../reduxSlices/wsDevicesSlice'
import { requestDevice } from '../reduxSlices/wsDeviceSlice'
import { requestDataLinks } from '../reduxSlices/dataLinkSlice'
import {
  requestNoiseData,
  resetNoiseData,
} from '../reduxSlices/wsNoiseDataSlice'
import { requestHistoricalData } from '../reduxSlices/wsHistoricalDataSlice'
import {
  requestStaTunnel,
  resetStaTunnel,
} from '../reduxSlices/wsStaTunnelSlice'
import { requestApTunnel } from '../reduxSlices/wsApTunnelSlice'
import { requestUser, updateUser } from '../reduxSlices/userSlice'
import { requestUsers } from '../reduxSlices/wsUsersSlice'
import { requestSite, resetSite } from '../reduxSlices/wsSiteSlice'
import { requestTopo } from '../reduxSlices/wsTopoSlice'
import { requestSearch, resetSearch } from '../reduxSlices/wsSearchSlice'
import { resetBestAps, requestBestAps } from '../reduxSlices/wsBestApsSlice'
import { resetRoomInfo } from '../reduxSlices/wsRoomInfoSlice'
import { resetActiveSockets } from '../reduxSlices/wsActiveSocketsSlice'
import { resetServerStatus } from '../reduxSlices/wsServerStatusSlice'
import { setupSocketEventListeners } from './socket/socketEvents'
import { requestSubscriber } from '../reduxSlices/subscriberSlice'
import { requestDispatchables } from '../reduxSlices/dispatchablesSlice'
import { requestMbsSearch } from '../reduxSlices/wsMbsSearchSlice'

const AppContext = createContext(null)

let socket
let ws

const AppProvider = ({ children }) => {
  console.log('AppProvider() called.')

  const dispatch = useDispatch()

  const [user, setUser] = useState(null)
  const [authFailure, setAuthFailure] = useState(false)
  const [isLoading, setLoading] = useState(true)

  const findUser = useCallback(async () => {
    try {
      const res = await axios.get('/api/auth/cookiechecker')
      setUser(res.data)
    } catch (err) {
      setAuthFailure(true)
      setUser(null)
    }
    setLoading(false)
  }, [])

  useEffect(() => {
    if (!user && !authFailure) {
      findUser()
    }
  }, [user, authFailure, findUser])

  const fetchUser = async (id) => {
    dispatch(requestUser())
    socket.emit('event://request-user', id)
  }

  const fetchUsers = async () => {
    dispatch(requestUsers())
    console.log('fetchUsers() called.')
    socket.emit('event://request-users')
  }

  const fetchUpdateUser = (id, data) => {
    dispatch(updateUser())
    socket.emit('event://request-user-update', id, data)
  }

  const requestFwUpdate = async (data) => {
    socket.emit('event://upgrade-firmware', data)
  }

  const fetchSitesExport = async () => {
    socket.emit('event://request-sites-export')
  }

  const fetchSiteSubscribersExport = async (id) => {
    socket.emit('event://request-site-subscriber-export', id)
  }

  const fetchAccountInfo = async (data) => {
    dispatch(requestSonar())
    socket.emit('event://request-customer-info', data)
  }

  const fetchMbsSearch = async (searchTerm) => {
    dispatch(requestMbsSearch())
    socket.emit('event://request-mbs-search', searchTerm)
  }

  const addRouter = async (data) => {
    socket.emit('event://add-router', data)
  }

  const addDevice = async (data) => {
    socket.emit('event://add-device', data)
  }

  const assignDevicesToSite = async (devices, site, siteId) => {
    dispatch(updateDevices())
    socket.emit('event://assign-devices', devices, site, siteId)
  }

  const removeDevices = async (devices, siteId) => {
    dispatch(updateDevices())
    socket.emit('event://remove-devices', devices, siteId)
  }

  const rebootDevice = async (device) => {
    dispatch(updateDevices())
    socket.emit('event://reboot-device', device)
  }

  const fetchSites = async () => {
    console.log('fetchSites() called.')
    dispatch(requestSites())
    socket.emit('event://request-sites')
  }

  const fetchSite = async (id) => {
    dispatch(requestSite())
    socket.emit('event://request-site', id)
  }

  const fetchDevices = async () => {
    dispatch(requestDevices())
    socket.emit('event://request-devices')
  }

  const searchDevices = async (query) => {
    dispatch(resetSearch())
    dispatch(requestSearch())
    socket.emit('event://request-search', query)
  }

  // const fetchDevice = async (id) => {
  //   dispatch(requestDevice(id))
  //   socket.emit('event://request-device', id)
  // }

  const fetchDevice = async (id) => {
    dispatch(requestDevice(id))
    socket.emit('event://request-device', id)
  }

  const fetchNoiseData = async (host) => {
    dispatch(requestNoiseData())
    socket.emit('event://request-noise-data', host)
  }

  const stopNoiseData = async (host) => {
    dispatch(resetNoiseData())
    socket.emit('event://stop-live-data', host)
  }

  const fetchHistoricalData = async (host, hours) => {
    dispatch(requestHistoricalData())
    socket.emit('event://request-device-history', host, hours)
  }

  const fetchStaTunnel = (apAddress, staAddress) => {
    if (apAddress && staAddress) {
      let payload = { apAddress, staAddress }
      socket.emit('event://request-sta-tunnel', payload)
      dispatch(requestStaTunnel())
    }
  }

  const joinTunnelRoom = (apId, staId) => {
    if (apId && staId) {
      let payload = { apId, staId }
      socket.emit('join://tunnel-room', payload)
      console.log(`joinTunnelRoom: ${apId} ${staId}`)
      dispatch(requestStaTunnel())
    }
  }

  const leaveTunnelRoom = (apId, staId) => {
    let payload = { apId, staId }
    socket.emit('leave://tunnel-room', payload)
    dispatch(resetStaTunnel())
  }

  const fetchStaTunnelClose = () => {
    socket.emit('event://close-sta-tunnel')
    dispatch(resetStaTunnel())
  }

  const fetchApTunnel = (apAddress, staAddress) => {
    if (apAddress && staAddress) {
      let payload = { apAddress, staAddress }
      socket.emit('event://request-ap-tunnel', payload)
      dispatch(requestApTunnel())
    }
  }

  const fetchApTunnelClose = () => {
    socket.emit('event://close-ap-tunnel')
  }

  const unassignService = async (device, service) => {
    socket.emit('event://unassign-service', device, service)
  }

  const assignDevicesToSubscriber = async (devices, assignment) => {
    socket.emit('event://assign-devices-to-subscriber', devices, assignment)
  }

  const provisionLtuClient = (id, config, port, note, username, password) => {
    console.log(id, config, port)
    dispatch(resetSonar())
    socket.emit('event://provision-device', {
      id,
      config,
      port,
      note,
      username,
      password,
    })
  }

  const editSite = (siteId, siteInfo) => {
    let payload = {
      siteId,
      siteInfo,
    }
    socket.emit('event://edit-site', payload)
  }

  const fetchDataLinks = async () => {
    dispatch(requestDataLinks())
    socket.emit('event://request-datalinks')
  }

  const fetchEvents = async ({ user, site, device, all }) => {
    dispatch(requestEvents())
    socket.emit('event://request-events', { user, site, device, all })
  }

  const fetchBestAps = async ({ lat, lng }, rxHeight, txHeight) => {
    dispatch(resetBestAps())
    dispatch(requestBestAps())
    socket.emit(
      'event://request-best-aps',
      { lat, long: lng },
      rxHeight,
      txHeight
    )
  }

  const fetchTopo = async (onlyTopo) => {
    dispatch(requestTopo())
    if (!onlyTopo) {
      socket.emit('join://topology')
    }
    socket.emit('event://request-network-graph-v2')
  }

  const leaveTopo = async () => {
    socket.emit('leave://topology')
  }

  const joinDeviceRoom = async (id) => {
    console.log('joined room')
    socket.emit('join://device-room', id)
  }
  const leaveDeviceRoom = async (id) => {
    console.log('left room')
    socket.emit('leave://device-room', id)
    dispatch(resetRoomInfo())
  }

  const joinSiteRoom = async (id) => {
    socket.emit('join://site-room', id)
    dispatch(requestSite())
  }
  const leaveSiteRoom = async (id) => {
    socket.emit('leave://site-room', id)
    dispatch(resetSite())
  }

  const joinSitesListRoom = async () => {
    socket.emit('join://sites-list')
    dispatch(requestSites())
  }
  const leaveSitesListRoom = async () => {
    socket.emit('leave://sites-list')
    dispatch(resetSites())
  }

  const joinSocketRoom = async () => {
    socket.emit('join://active-socket-room')
  }

  const leaveSocketRoom = async () => {
    socket.emit('leave://active-socket-room')
    dispatch(resetActiveSockets())
  }
  const joinServerRoom = async () => {
    socket.emit('join://server-status')
  }

  const leaveServerRoom = async () => {
    socket.emit('leave://server-status')
    dispatch(resetServerStatus())
  }

  const fetchLanRefresh = (id) => {
    socket.emit('event://request-lan-refresh', id)
  }

  const fetchSubscriber = (id) => {
    dispatch(requestSubscriber())
    socket.emit('event://request-subscriber', id)
  }

  const fetchDispatchables = () => {
    dispatch(requestDispatchables())
    socket.emit('event://request-dispatchables')
  }

  const cycleIface = (payload) => {
    socket.emit('event://request-cycle-iface', payload)
  }

  if (user && !socket) {
    socket = io({
      extraHeaders: {
        'client-location': window.location.toString(),
      },
      auth: {
        sgClientVersion: process.env.REACT_APP_VERSION,
      },
      path: '/socket',
      transports: ['websocket', 'polling'],
    })
    socket.auth.user = user
    socket.connect()

    ws = {
      fetchUsers,
      fetchUser,
      fetchUpdateUser,
      requestFwUpdate,
      fetchAccountInfo,
      addRouter,
      addDevice,
      assignDevicesToSite,
      fetchSites,
      fetchSite,
      fetchDevices,
      searchDevices,
      fetchDevice,
      fetchNoiseData,
      stopNoiseData,
      fetchHistoricalData,
      fetchStaTunnel,
      fetchStaTunnelClose,
      fetchApTunnel,
      fetchApTunnelClose,
      provisionLtuClient,
      assignDevicesToSubscriber,
      unassignService,
      editSite,
      fetchDataLinks,
      fetchSitesExport,
      fetchSiteSubscribersExport,
      fetchBestAps,
      removeDevices,
      rebootDevice,
      fetchEvents,
      fetchTopo,
      leaveTopo,
      joinDeviceRoom,
      leaveDeviceRoom,
      joinSiteRoom,
      leaveSiteRoom,
      joinSocketRoom,
      leaveSocketRoom,
      joinServerRoom,
      leaveServerRoom,
      fetchLanRefresh,
      joinTunnelRoom,
      leaveTunnelRoom,
      fetchSubscriber,
      fetchDispatchables,
      joinSitesListRoom,
      leaveSitesListRoom,
      fetchMbsSearch,
      cycleIface
    }
    setupSocketEventListeners(socket, dispatch, ws)
  }

  return (
    <AppContext.Provider
      value={{
        authFailure,
        user,
        findUser,
        isLoading,
        ws,
        fetchUsers,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default AppProvider

export const useAppContext = () => useContext(AppContext)
