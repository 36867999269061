export const buildMap = (data) => {
  if (data.function === 'ap-ptmp') {
    const site = { ...data['site'][0] }
    site.devices = data.stations
    return [site]
  } else {
    const site = { ...data['site'][0] }
    site.devices = [data]
    return [site]
  }
}

export const parseValues = (ap, sta) => {
  console.log(ap)
  console.log(sta)
  let dlStaPercent = null
  let ulStaPercent = null
  let staSignalPercent = null
  let apSignalPercent = null

  const dlPercent = (ap?.dlThroughput / 1000000 / (ap?.dlCapacity / 1000)) * 100
  const ulPercent = (ap?.ulThroughput / 1000000 / (ap?.ulCapacity / 1000)) * 100

  if (sta) {
    dlStaPercent =
      (sta.wireless?.dlThroughput /
        1000000 /
        (sta.wireless?.dlCapacity / 1000)) *
      100
    ulStaPercent =
      (sta.wireless?.ulThroughput /
        1000000 /
        (sta.wireless?.ulCapacity / 1000)) *
      100
    staSignalPercent = Math.round(
      (sta.wireless?.expectedSignal / sta.wireless?.signal) * 100
    )
  }
  return {
    dlPercent,
    ulPercent,
    dlStaPercent,
    ulStaPercent,
    staSignalPercent,
  }
}

export const signalCompare = (expectedSignal, realSignal) => {
  let linkQuality
  let color
  let score
  const difference = Math.abs(realSignal - expectedSignal)

  if (difference <= 3) {
    linkQuality = 'Perfect'
    color = 'cyan'
    score = 100 - difference * 2 // Closer to 100 for smaller differences
  } else if (difference <= 7) {
    linkQuality = 'Good'
    color = 'green'
    score = 94 - (difference - 3) * 4 // Decreasing score as difference increases
  } else if (difference <= 12) {
    if (realSignal >= -60) {
      linkQuality = 'Good'
      color = 'green'
      score = 78 - (difference - 7) * 3
    } else {
      linkQuality = 'Average'
      color = 'orange'
      score = 63 - (difference - 7) * 2
    }
  } else {
    if (realSignal >= -65) {
      linkQuality = 'Average'
      color = 'orange'
      score = 43 - (difference - 12) * 2
    } else {
      linkQuality = 'Poor'
      color = 'red'
      score = Math.max(0, 31 - (difference - 12) * 3) // Ensures score doesn't go negative
    }
  }
  return color
}

export const getGreenToRed = (percent) => {
  const greenScaleFactor = 1.2 // Increase this value to make greens more vibrant
  const redScaleFactor = 1.2 // Increase this value to make reds more vibrant

  const redValue = Math.min(255, (255 * percent * redScaleFactor) / 100)
  const greenValue = Math.min(
    255,
    (255 - (255 * percent) / 100) * greenScaleFactor
  )

  return `rgb(${redValue},${greenValue},0)`
}

export const parseNoise = (device, noise) => {
  const utilizedSpread = device?.wireless.channelWidth / 10
  const lowerBound = device?.wireless.activeFrequency - 5 * utilizedSpread
  const upperBound = device?.wireless.activeFrequency + 5 * utilizedSpread

  const filteredNoise = noise
    .map((el, i) => ({
      channel: i * 5 + 4800,
      noise: el - 125,
      color: getGreenToRed(el * 1.25),
      utilized: i * 5 + 4800 >= lowerBound && i * 5 + 4800 <= upperBound,
    }))
    .slice(71, 209)

  const start = 5155
  const noiseData =
    noise?.slice(10, 149).map((el, i) => {
      const currentChannel = start + i * 5
      const utilized =
        currentChannel >= lowerBound && currentChannel <= upperBound
      return {
        channel: currentChannel,
        noise: el,
        color: getGreenToRed(el * 1.25),
        utilized,
      }
    }) || []

  return filteredNoise
}

export const parseAp = (dbData, liveData) => {
  const apData = {
    _id: dbData?._id,
    site: dbData?.site,
    name: liveData ? liveData?.host.hostname : dbData.name,
    mac: liveData
      ? liveData?.interfaces.filter((el) => el.ifname === 'ath0')[0]?.hwaddr
      : dbData.mac,
    type: liveData ? liveData?.host.devmodel : dbData.model,
    model: liveData ? liveData?.host.devmodel : dbData.model,
    function: dbData.function,
    family: dbData.family,
    ip: dbData.ip,
    firmware: liveData ? liveData?.host.fwversion : dbData.firmware,
    channel: dbData.wireless.channel,
    channelWidth: dbData.wireless.channelWidth,
    staCount: liveData ? liveData?.wireless.count : dbData.wireless.staCount,
    location: dbData.location,
    updatedAt: dbData.updatedAt,
    liveConnection: liveData ? true : false,
    connected: liveData ? true : dbData.connected,
    lastContact: dbData.lastContact,
    uptime: liveData ? liveData.host.uptime : dbData.uptime,
  }

  switch (dbData.family) {
    case 'ltu':
      apData.dlScore = liveData
        ? liveData?.wireless.polling.dl_score
        : dbData.wireless?.dlScore

      apData.ulScore = liveData
        ? liveData?.wireless.polling.ul_score
        : dbData.wireless?.ulScore

      apData.dlThroughput = liveData
        ? liveData?.wireless.stats?.tx_throughput
        : dbData.wireless?.dlThroughput

      apData.ulThroughput = liveData
        ? liveData?.wireless.stats?.rx_throughput
        : dbData.wireless?.ulThroughput

      apData.dlCapacity = liveData
        ? liveData?.wireless.polling.dcap
        : dbData.wireless?.dlCapacity

      apData.ulCapacity = liveData
        ? liveData?.wireless.polling.ucap
        : dbData.wireless?.ulCapacity

      apData.lanSpeed = liveData
        ? liveData?.interfaces.filter((el) => el.ifname === 'ath0')[0].status
            .speed
        : dbData.lan?.lanSpeed

      apData.lanDuplex = liveData
        ? liveData?.interfaces.filter((el) => el.ifname === 'ath0')[0].status
            .duplex
        : dbData.lan?.lanDuplex

      apData.lanTxErrors = liveData
        ? liveData?.interfaces.filter((el) => el.ifname === 'ath0')[0].status
            .tx_errors
        : dbData.lan?.lanTxErrors

      apData.lanRxErrors = liveData
        ? liveData?.interfaces.filter((el) => el.ifname === 'ath0')[0].status
            .rx_errors
        : dbData.lan?.lanRxErrors

      apData.lanSNR = liveData
        ? liveData?.interfaces.filter((el) => el.ifname === 'ath0')[0].status
            .snr
        : dbData.lan?.lanSNR

      break
    case 'af60':
      apData.dlScore = liveData
        ? liveData?.wireless.sta[0]?.prs_sta?.dl_avg_linkscore
        : dbData.wireless?.dlScore

      apData.ulScore = liveData
        ? liveData?.wireless.sta[0]?.prs_sta?.ul_avg_linkscore
        : dbData.wireless?.ulScore

      apData.dlThroughput = liveData
        ? liveData?.wireless.throughput.tx
        : dbData.wireless?.dlThroughput

      apData.ulThroughput = liveData
        ? liveData?.wireless.throughput.rx
        : dbData.wireless?.ulThroughput

      apData.dlCapacity = liveData
        ? liveData?.wireless.sta[0]?.prs_sta?.dl_capacity
        : dbData.wireless?.dlCapacity

      apData.ulCapacity = liveData
        ? liveData?.wireless.sta[0]?.prs_sta?.ul_capacity
        : dbData.wireless?.ulCapacity

      apData.lanSpeed = liveData
        ? liveData?.interfaces.filter((el) => el.ifname === 'eth0')[0].status
            .speed
        : dbData.lan?.lanSpeed

      apData.lanDuplex = liveData
        ? liveData?.interfaces.filter((el) => el.ifname === 'eth0')[0].status
            .duplex
        : dbData.lan?.lanDuplex

      apData.lanTxErrors = liveData
        ? liveData?.interfaces.filter((el) => el.ifname === 'eth0')[0].status
            .tx_errors
        : dbData.lan?.lanTxErrors

      apData.lanRxErrors = liveData
        ? liveData?.interfaces.filter((el) => el.ifname === 'eth0')[0].status
            .rx_errors
        : dbData.lan?.lanRxErrors

      apData.lanSNR = liveData
        ? liveData?.interfaces.filter((el) => el.ifname === 'eth0')[0].status
            .snr
        : dbData.lan?.lanSNR

      break
    case 'airmax':
      apData.lanSpeed = liveData
        ? liveData?.interfaces.filter((el) => el.ifname === 'eth0')[0].status
            .speed
        : dbData.lan?.lanSpeed

      apData.lanDuplex = liveData
        ? liveData?.interfaces.filter((el) => el.ifname === 'eth0')[0].status
            .duplex
        : dbData.lan?.lanDuplex

      apData.lanTxErrors = liveData
        ? liveData?.interfaces.filter((el) => el.ifname === 'eth0')[0].status
            .tx_errors
        : dbData.lan?.lanTxErrors

      apData.lanRxErrors = liveData
        ? liveData?.interfaces.filter((el) => el.ifname === 'eth0')[0].status
            .rx_errors
        : dbData.lan?.lanRxErrors

      apData.lanSNR = liveData
        ? liveData?.interfaces.filter((el) => el.ifname === 'eth0')[0].status
            .snr
        : dbData.lan?.lanSNR

      apData.dlThroughput = liveData
        ? liveData?.wireless.throughput.tx
        : dbData.wireless?.dlThroughput

      apData.ulThroughput = liveData
        ? liveData?.wireless.throughput.rx
        : dbData.wireless?.ulThroughput

      apData.dlCapacity = liveData
        ? liveData?.wireless.polling.dcap
        : dbData.wireless?.dlCapacity

      apData.ulCapacity = liveData
        ? liveData?.wireless.polling.ucap
        : dbData.wireless?.ulCapacity
      break
    case 'epmp':
      apData.lanSpeed = dbData.lan?.lanSpeed

      apData.lanDuplex = dbData.lan?.lanDuplex

      apData.lanTxErrors = dbData.lan?.lanTxErrors

      apData.lanRxErrors = dbData.lan?.lanRxErrors

      apData.lanSNR = dbData.lan?.lanSNR

      apData.dlThroughput = dbData.wireless?.dlThroughput

      apData.ulThroughput = dbData.wireless?.ulThroughput
      break
  }
  return apData
}

export const parseStation = (station, liveData) => {
  const staData = {
    _id: station?._id,
    name: liveData ? liveData?.remote?.hostname : station?.name,
    mac: liveData ? liveData?.mac : station?.mac,
    firmware: station?.firmware,
    type: liveData ? liveData?.remote?.devmodel : station?.model,
    model: liveData ? liveData?.remote?.devmodel : station?.model,
    function: station?.function,
    family: station?.family,
    //autoPower: liveData
    //  ? liveData.remote?.auto_txpwr
    //  : station?.wireless.autoPower,
    location: station?.location,
    hasGps: liveData?.remote?.has_gps,
    distance: liveData ? liveData?.distance : station?.wireless?.distance,
    updatedAt: station?.updatedAt,
    liveConnection: liveData ? true : false,
    connected: liveData ? true : station?.connected,
    lastContact: station?.lastContact,
    shaping: station?.shaping,
    lastConfiguredBySg: station?.lastConfiguredBySg,
  }

  switch (station?.family) {
    case 'ltu':
      const lanInterface = liveData?.remote?.ethlist?.filter(
        (el) => el?.role === 'data'
      )[0]
      staData.apExpectedSignal = liveData
        ? (liveData.idealpwr0 + liveData.idealpwr1) / 2
        : station?.wireless?.expectedSignal

      staData.apSignal = liveData
        ? (liveData.receive_power0 + liveData.receive_power1) / 2
        : station?.wireless?.signal

      staData.staExpectedSignal = liveData
        ? (liveData.remote?.idealpwr0 + liveData.remote?.idealpwr1) / 2
        : station?.wireless?.expectedSignal

      staData.staSignal = liveData
        ? (liveData.remote?.receive_power0 + liveData.remote?.receive_power1) /
          2
        : station?.wireless?.signal

      staData.dlScore = liveData
        ? liveData?.dl_score
        : station?.wireless?.dlScore

      staData.ulScore = liveData
        ? liveData?.ul_score
        : station?.wireless?.ulScore

      staData.dlCapacity = liveData
        ? liveData.airmax?.downlink_capacity
        : station?.wireless?.dlCapacity

      staData.ulCapacity = liveData
        ? liveData.airmax?.uplink_capacity
        : station?.wireless?.ulCapacity

      staData.dlThroughput = liveData
        ? liveData.stats?.rx_throughput
        : station?.wireless?.dlThroughput

      staData.ulThroughput = liveData
        ? liveData.stats?.tx_throughput
        : station?.wireless?.ulThroughput

      staData.lanStatus = liveData
        ? lanInterface?.plugged
        : station?.lan?.lanStatus

      staData.lanSpeed = liveData ? lanInterface?.speed : station?.lan?.lanSpeed

      staData.lanDuplex = liveData
        ? lanInterface?.duplex
        : station?.lan?.lanDuplex

      staData.lanTxErrors = liveData
        ? lanInterface?.tx_errors
        : station?.lan?.lanTxErrors

      staData.lanRxErrors = liveData
        ? lanInterface?.rx_errors
        : station?.lan?.lanRxErrors

      staData.lanSNR = liveData ? lanInterface?.snr : station?.lan?.lanSNR

      staData.linkLocalAddr = liveData
        ? liveData.remote?.ip6addr?.filter((ip) => ip.startsWith('fe80'))
        : station?.linkLocalAddr

      staData.ip = liveData ? liveData?.lastip : station?.ip

      staData.uptime = liveData ? liveData?.uptime : station?.uptime

      staData.learnedMacs = station?.learnedMacs
      break

    case 'airmax':
      staData.apExpectedSignal = liveData
        ? liveData?.ul_signal_expect
        : station?.wireless?.expectedSignal

      staData.apSignal = liveData ? liveData?.signal : station?.wireless?.signal

      staData.staExpectedSignal = liveData
        ? liveData?.ul_signal_expect
        : station?.wireless?.expectedSignal

      staData.staSignal = liveData
        ? liveData?.remote?.signal
        : station?.wireless?.signal

      staData.dlScore = liveData
        ? liveData?.dl_linkscore
        : station?.wireless?.dlScore

      staData.ulScore = liveData
        ? liveData?.ul_linkscore
        : station?.wireless?.ulScore

      staData.dlCapacity = liveData
        ? liveData?.airmax?.downlink_capacity
        : station?.wireless?.dlCapacity

      staData.ulCapacity = liveData
        ? liveData?.airmax?.uplink_capacity
        : station?.wireless?.ulCapacity

      staData.dlThroughput = liveData
        ? liveData?.remote?.rx_throughput
        : station?.wireless?.dlThroughput

      staData.ulThroughput = liveData
        ? liveData?.remote?.tx_throughput
        : station?.wireless?.ulThroughput

      staData.lanStatus = liveData
        ? liveData.remote?.ethlist?.filter((el) => el?.ifname === 'eth0')[0]
            ?.plugged
        : station?.lan?.lanStatus

      staData.lanSpeed = liveData
        ? liveData.remote?.ethlist?.filter((el) => el?.ifname === 'eth0')[0]
            ?.speed
        : station?.lan?.lanSpeed

      staData.lanDuplex = liveData
        ? liveData.remote?.ethlist?.filter((el) => el?.ifname === 'eth0')[0]
            ?.duplex
        : station?.lan?.lanDuplex

      staData.lanTxErrors = liveData
        ? liveData.remote?.ethlist?.filter((el) => el?.ifname === 'eth0')[0]
            ?.tx_errors
        : station?.lan?.lanTxErrors

      staData.lanRxErrors = liveData
        ? liveData.remote?.ethlist?.filter((el) => el?.ifname === 'eth0')[0]
            ?.rx_errors
        : station?.lan?.lanRxErrors

      staData.lanSNR = liveData
        ? liveData.remote?.ethlist?.filter((el) => el?.ifname === 'eth0')[0]
            ?.snr
        : station?.lan?.lanSNR

      staData.linkLocalAddr = liveData
        ? liveData.remote?.ip6addr?.filter((ip) => ip.startsWith('fe80'))
        : station?.linkLocalAddr

      staData.ip = liveData ? liveData?.lastip : station?.ip

      staData.uptime = liveData ? liveData?.uptime : station?.uptime
      break
    case 'af60':
      staData.apExpectedSignal = liveData
        ? liveData?.ul_signal_expect
        : station?.wireless?.expectedSignal

      staData.apSignal = liveData ? liveData?.signal : station?.wireless?.signal

      staData.staExpectedSignal = liveData
        ? liveData?.prs_sta?.dl_signal_expect
        : station?.wireless?.expectedSignal

      staData.staSignal = liveData
        ? liveData?.prs_sta?.rssi_data
        : station?.wireless?.signal

      staData.dlScore = liveData
        ? liveData?.prs_sta?.dl_avg_linkscore
        : station?.wireless?.dlScore

      staData.ulScore = liveData
        ? liveData?.prs_sta?.ul_avg_linkscore
        : station?.wireless?.ulScore

      staData.dlCapacity = liveData
        ? liveData?.prs_sta?.dl_capacity
        : station?.wireless?.dlCapacity

      staData.ulCapacity = liveData
        ? liveData?.prs_sta?.ul_capacity
        : station?.wireless?.ulCapacity

      staData.dlThroughput = liveData
        ? liveData?.remote?.rx_throughput
        : station?.wireless?.dlThroughput

      staData.ulThroughput = liveData
        ? liveData?.remote?.tx_throughput
        : station?.wireless?.ulThroughput

      staData.lanStatus = liveData
        ? liveData.remote?.ethlist?.filter((el) => el?.ifname === 'eth0')[0]
            ?.plugged
        : station?.lan?.lanStatus

      staData.lanSpeed = liveData
        ? liveData.remote?.ethlist?.filter((el) => el?.ifname === 'eth0')[0]
            ?.speed
        : station?.lan?.lanSpeed

      staData.lanDuplex = liveData
        ? liveData.remote?.ethlist?.filter((el) => el?.ifname === 'eth0')[0]
            ?.duplex
        : station?.lan?.lanDuplex

      staData.lanTxErrors = liveData
        ? liveData.remote?.ethlist?.filter((el) => el?.ifname === 'eth0')[0]
            ?.tx_errors
        : station?.lan?.lanTxErrors

      staData.lanRxErrors = liveData
        ? liveData.remote?.ethlist?.filter((el) => el?.ifname === 'eth0')[0]
            ?.rx_errors
        : station?.lan?.lanRxErrors

      staData.lanSNR = liveData
        ? liveData.remote?.ethlist?.filter((el) => el?.ifname === 'eth0')[0]
            ?.snr
        : station?.lan?.lanSNR

      staData.linkLocalAddr = liveData
        ? liveData.remote?.ip6addr?.filter((ip) => ip.startsWith('fe80'))
        : station?.linkLocalAddr

      staData.ip = liveData ? liveData?.remote.ipaddr[0] : station?.ip

      staData.uptime = liveData ? liveData?.remote.uptime : station?.uptime
      break

    case 'epmp':
      staData.apExpectedSignal = station?.wireless?.expectedSignal

      staData.apSignal = station?.wireless?.signal

      staData.staExpectedSignal = station?.wireless?.expectedSignal

      staData.staSignal = station?.wireless?.signal

      staData.dlScore = station?.wireless?.dlScore

      staData.ulScore = station?.wireless?.ulScore

      staData.dlCapacity = station?.wireless?.dlCapacity

      staData.ulCapacity = station?.wireless?.ulCapacity

      staData.dlThroughput = station?.wireless?.dlThroughput

      staData.ulThroughput = station?.wireless?.ulThroughput

      staData.lanStatus = station?.lan?.lanStatus

      staData.lanSpeed = station?.lan?.lanSpeed

      staData.lanDuplex = station?.lan?.lanDuplex

      staData.lanTxErrors = station?.lan?.lanTxErrors

      staData.lanRxErrors = station?.lan?.lanRxErrors

      staData.lanSNR = station?.lan?.lanSNR

      staData.linkLocalAddr = station?.linkLocalAddr

      staData.uptime = station?.uptime
      break
  }

  return staData
}

export const parseStations = (stations, data) => {
  let stationData = []
  stations?.map((station) => {
    let sta = data?.wireless.sta.filter(
      (liveStation) => liveStation.mac === station.mac
    )[0]
    const lanInterface = sta?.remote?.ethlist?.filter(
      (el) => el?.role === 'data'
    )[0]
    let staDataCombined = {
      _id: station?._id,
      uplink: station?.uplink,
      name: sta ? sta?.remote?.hostname : station?.name,
      mac: sta ? sta?.mac : station?.mac,
      ip: sta ? sta?.lastip : station?.ip,
      firmware: sta
        ? sta?.remote.version.split('.').slice(2, 5).join('.')
        : station?.firmware,
      type: sta ? sta?.remote?.devmodel : station?.type,
      model: sta ? sta?.remote?.devmodel : station?.model,
      function: station?.function,
      apExpectedSignal: sta
        ? (sta?.idealpwr0 + sta?.idealpwr1) / 2
        : station?.wireless?.expectedSignal,
      apSignal: sta
        ? (sta?.receive_power0 + sta?.receive_power1) / 2
        : station?.wireless?.signal,
      staExpectedSignal: sta
        ? (sta?.remote?.idealpwr0 + sta?.remote?.idealpwr1) / 2
        : station?.wireless?.expectedSignal,
      staSignal: sta
        ? (sta?.remote?.receive_power0 + sta?.remote?.receive_power1) / 2
        : station?.wireless?.signal,
      dlScore: sta ? sta?.dl_score : station?.wireless?.dlScore,
      ulScore: sta ? sta?.ul_score : station?.wireless?.ulScore,
      dlCapacity: sta
        ? sta?.airmax?.downlink_capacity
        : station?.wireless?.dlCapacity,
      ulCapacity: sta
        ? sta?.airmax?.uplink_capacity
        : station?.wireless?.ulCapacity,
      dlThroughput: sta
        ? sta?.stats?.rx_throughput
        : station?.wireless?.dlThroughput,
      ulThroughput: sta
        ? sta?.stats?.tx_throughput
        : station?.wireless?.ulThroughput,
      lanStatus: sta ? lanInterface?.plugged : station?.lan?.lanStatus,
      lanSpeed: sta ? lanInterface?.speed : station?.lan?.lanSpeed,
      lanDuplex: sta ? lanInterface?.duplex : station?.lan?.lanDuplex,
      lanTxErrors: sta ? lanInterface?.tx_errors : station?.lan?.lanTxErrors,
      lanRxErrors: sta ? lanInterface?.rx_errors : station?.lan?.lanRxErrors,
      lanSNR: sta ? lanInterface?.snr : station?.lan?.lanSNR,
      linkLocalAddr: sta
        ? sta?.remote?.ip6addr?.filter((ip) => ip.startsWith('fe80'))
        : station?.linkLocalAddr,
      location: station?.location,
      autoPower: sta ? sta?.remote?.auto_txpwr : station.wireless.autoPower,
      hasGps: sta?.remote?.has_gps,
      distance: sta ? sta?.distance : station?.wireless?.distance,
      updatedAt: station?.updatedAt,
      liveConnection: sta ? true : false,
      connected: sta ? true : station?.connected,
      lastContact: station?.lastContact,
    }
    stationData.push(staDataCombined)
  })
  return stationData
}
