import React, { useState, useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { useTheme } from '@emotion/react'
import Control from 'react-leaflet-custom-control'
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer'
import { Bezier } from "bezier-js"
import {
  MapContainer,
  CircleMarker,
  Marker,
  Popup,
  Polyline,
  useMapEvent,
} from 'react-leaflet'

import L, { FeatureGroup } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { Switch, FormControlLabel, Badge, Typography, Paper, Menu, MenuItem } from '@mui/material'
import { useGetUsers } from '../../../hooks'
import { generateUrl } from '../../../utils/generateUrl'

import { createControlComponent } from '@react-leaflet/core'
import 'leaflet.fullscreen'
import '@changey/react-leaflet-markercluster/dist/styles.min.css'
import { useAppContext } from '../../../context/AppProvider'
import siteIcon from '../../Core/SiteIcon'

const FullscreenControl = createControlComponent(props => L.control.fullscreen(props))

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png').default,
  iconUrl: require('leaflet/dist/images/marker-icon.png').default,
  shadowUrl: require('leaflet/dist/images/marker-shadow.png').default,
})

const createCurvedPath = (latlngs, direction) => {
  if (!Array.isArray(latlngs) || latlngs.length !== 2) {
    throw new Error('Input must be an array of 2 LatLng arrays')
  }

  latlngs.forEach((pair, i) => {
    if (!Array.isArray(pair) || pair.length !== 2) {
      throw new Error(`LatLng pair ${i + 1} must be an array with 2 elements`)
    }
    pair.forEach((coord, j) => {
      if (typeof coord !== 'number') {
        throw new Error(`LatLng pair ${i + 1}, element ${j + 1} must be a number`)
      }
    })
  })

  const midPointLat = (latlngs[0][0] + latlngs[1][0]) / 2
  const midPointLng = (latlngs[0][1] + latlngs[1][1]) / 2

  let offset = 0.01 // Adjust this value to change the curve amount
  if (direction === 'in') {
    offset *= -1  // or use positive for 'to' and negative for 'from' depending on your requirement
  }

  const controlPoint = [midPointLat + offset, midPointLng] // only one control point for quadratic Bezier

  const path = new Bezier([
    { x: latlngs[0][1], y: latlngs[0][0] }, // Swap lat/lng to lng/lat for Bezier
    { x: controlPoint[1], y: controlPoint[0] }, // the control point
    { x: latlngs[1][1], y: latlngs[1][0] }
  ])

  return path.getLUT(100).map(point => [point.y, point.x])  // Convert back to lat/lng
}



const ContextMenu = () => {
  const [menuPosition, setMenuPosition] = useState(null)

  const handleClose = () => {
    setMenuPosition(null)
  }

  useMapEvent('contextmenu', (e) => {
    e.originalEvent.preventDefault()
    console.log(e.latlng)
    setMenuPosition({
      top: e.originalEvent.clientY,
      left: e.originalEvent.clientX,
    })
  })



  return (
    <Menu
      keepMounted
      open={menuPosition !== null}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={
        menuPosition !== null
          ? { top: menuPosition.top, left: menuPosition.left }
          : undefined
      }
    >
      <MenuItem onClick={handleClose}>Future features</MenuItem>
      {/* Add more menu items as you like */}
    </Menu>
  )
}




const NetworkMap = ({ sites, dataLinks }) => {


  const theme = useTheme()
  const { ws } = useAppContext()
  const { topo } = useSelector((state) => state.wsTopo)
  const { staId } = useParams()
  useGetUsers()




  const group = new FeatureGroup()
  sites.forEach((site) => {
    L.marker([site?.location?.lat, site?.location?.long]).addTo(group)
    site.devices
      .filter((device) => device?.function === 'sta-ptmp')
      .filter((device) => device?.location?.lat && device?.location?.long)
      .forEach((cpe) => {
        L.marker([cpe?.location?.lat, cpe?.location?.long]).addTo(group)
      })
  })

  const [showClients, setShowClients] = useState(sites.length > 1 ? false : true)

  const lines = topo?.edges.map((edge) => {

    const fromSite = sites.filter((site) => site._id === edge.from)[0]
    const toSite = sites.filter((site) => site._id === edge.to)[0]

    if (fromSite?.location?.lat && fromSite?.location?.long && toSite?.location?.lat && toSite?.location?.long && fromSite._id !== '644c2dcb801db33b9d40cc7b' && toSite._id !== '644c2dcb801db33b9d40cc7b' && fromSite._id !== '644c2dd1801db33b9d40cca3' && toSite._id !== '644c2dd1801db33b9d40cca3') {
      return {
        _id: edge._id,
        active: edge.active,
        expired: edge.expired,
        direction: edge.direction,
        interface: edge.interface,
        throughput: edge.throughput,
        from: [Number(fromSite.location.lat), Number(fromSite.location.long)],
        to: [Number(toSite.location.lat), Number(toSite.location.long)],
      }
    }
  })

  const polylineColor = (dlScore, ulScore) => {
    const score = ((dlScore + ulScore) / 2)
    switch (true) {
      case score >= 85:
        return 'green'
      case score >= 65:
        return 'yellow'
      case score >= 40:
        return 'orange'
      default:
        return 'red'
    }
  }


  useEffect(() => {
    ws.fetchTopo(true)
  }, [ws])




  return (
    <div style={{ display: 'flex', height: '100%', minHeight: '20vh', maxHeight: '35vh' }}>
      <MapContainer
        zoom={15}
        preferCanvas={true}
        attributionControl={false}
        style={{ flexGrow: 1 }}
        bounds={group.getBounds()}
        scrollWheelZoom={true}>
        <FullscreenControl />
        <ReactLeafletGoogleLayer
          apiKey='AIzaSyC5nxdHvnhMxJSjSe_ydfeMK1OJ12sSOxI'
          type={'hybrid'}
        />
        <ContextMenu />
        <Control style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }} position='topright'>
          <FormControlLabel
            control={
              <Switch
                checked={showClients}
                onChange={() => setShowClients(!showClients)}
              />
            }
            label='Clients'
          />
        </Control>

        {lines.map((line) => {

          if (line?.to && line?.from) {
            return (
              <div key={line._id}>
                <Polyline
                  pathOptions={{
                    color: line.active && line.expired ? 'red' : line.expired ? 'orange' : line.active ? 'green' : 'grey',
                  }}
                  weight={3}
                  dashArray={line.direction === 'in' ? false : '5, 10'}
                  positions={[
                    createCurvedPath([line.from, line.to], line.direction)
                  ]}>
                </Polyline>
              </div>
            )
          }
        })}

        {sites.map((site) => (
          <div key={site._id}>
            <Marker
              icon={siteIcon(site, L)}
              position={[site?.location?.lat, site?.location?.long]}>
              <Popup>
                <Link
                  style={{ textDecoration: 'inherit', color: 'inherit' }}
                  to={`/network/site/${site._id}`}>
                  {site?.name}
                </Link>
              </Popup>
            </Marker>

            {showClients &&
              site.devices
                .filter((device) => device.function === 'sta-ptmp')
                .filter((device) => device.location?.lat && device.location?.long)
                .map((device) => (
                  <div key={device._id}>
                    <Polyline
                      weight={device._id === staId ? 8 : 3}
                      dashArray={device._id === staId ? false : '5, 10'}
                      pathOptions={{
                        color: polylineColor(
                          device.wireless.dlScore,
                          device.wireless.ulScore
                        ),
                      }}
                      positions={[
                        [site?.location?.lat, site?.location?.long],
                        [device?.location?.lat, device?.location?.long],
                      ]}>
                      <Popup>
                        <Paper sx={{ p: 2 }}>
                          <Link
                            style={{ textDecoration: 'inherit', color: 'inherit' }}
                            to={generateUrl(device)}>
                            <Typography variant="subtitle1" fontWeight="bold" mb={1}>
                              {device.name}
                            </Typography>
                          </Link>
                          <Typography variant="body2" mb={1}>
                            Signal: {device.wireless.signal}
                          </Typography>
                          <Typography variant="body2" mb={1}>
                            Download Score: {device.wireless.dlScore}%
                          </Typography>
                          <Typography variant="body2" mb={1}>
                            Upload Score: {device.wireless.ulScore}%
                          </Typography>
                        </Paper>
                      </Popup>
                    </Polyline>
                    <CircleMarker
                      radius={device._id === staId ? 5 : 2}
                      color={device.connected ? 'blue' : 'red'}
                      center={[device?.location?.lat, device?.location?.long]}>
                      <Popup>
                        <Link
                          style={{ textDecoration: 'inherit', color: 'inherit' }}
                          to={generateUrl(device)}>
                          {device.name}
                        </Link>
                        <br />
                        Signal: {device.wireless.signal}
                        <br />
                        Download Score: {device.wireless.dlScore}%
                        <br />
                        Upload Score: {device.wireless.ulScore}%
                      </Popup>
                    </CircleMarker>
                  </div>
                ))}
          </div>
        ))
        }
      </MapContainer >
    </div >
  )
}

export default NetworkMap